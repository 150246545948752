import React,{useState,useEffect } from "react"
import { navigate  } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Caja from "../../components/camping/caja"

import ReactTable from 'react-table-6'
import { Button,Col,Row,Container } from 'reactstrap';
import moment from "moment"
import Amplify, {API,graphqlOperation,Auth } from 'aws-amplify';
import {checkout} from "../../graphql/mutations"
import awsconfig from '../../aws-exports'; 
import {connect} from 'react-redux'
import { I18n } from 'aws-amplify';
import {actualizarTabla} from '../../Funciones/utils'
//ACTIONS
import setModalAction from '../../redux/actions/setModalCaja'
import setIngresoAction from '../../redux/actions/setIngreso'

const authScreenLabels = {
  es: {
      'Sign In': 'Entrar',
  }
};
I18n.setLanguage('es');
I18n.putVocabularies(authScreenLabels);

Amplify.configure(awsconfig)

moment.locale('es')

const withCaja=(componente,dispatch,props)=><div className="test-WithCaja" style={{cursor:"pointer"}} onClick={()=>{dispatch(setModalAction(true));dispatch(setIngresoAction(props.row._original))}}>{componente}</div>

export const IndexPage = ({listaIngresos:listaTotal,mobile,currentIngreso,dispatch,modal}) => {
  const [data,setData] =useState([]);
  const [listaDeudores,setListaDeudores] =useState([]);
  const [listaEstadia,setListaEstadia] =useState([]);
  const [deudores,setDeudores] =useState(false);
  const [historico,setHistorico] =useState(false);
  const [loading,setLoading] =useState(true);
  //Auth.signOut();
  useEffect(() => {
    //Solo llama a la funcion si el usuario esta autenticado
    Auth.currentAuthenticatedUser({
      bypassCache: false  // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
  }).then(user => 
    //Funcion que actualiza la tabla
    actualizarTabla(setLoading,dispatch)

    ).catch(err => {setLoading(false);});

  },[]);

  useEffect(() => {
    setListaDeudores(listaTotal.filter(x=>x.deudor));
    setListaEstadia(listaTotal.filter(x=>!x.tieneCheckout));
      },[listaTotal]);
    
  useEffect(() => {
    setData((deudores)?listaDeudores:listaEstadia);
      },[listaDeudores,listaEstadia]);
      navigate("/habitaciones")
  var columns = [
    {
    Header: 'Entrada',
    accessor: 'fechaIngreso',
    width: 160,
    Cell: props => withCaja(moment(props.value).fromNow(),dispatch,props),
    filterable: false
      },{
    Header: 'Nombre y Apellido',
    accessor: 'nombreyape',
    width: 270,
    Cell: props => withCaja(props.value,dispatch,props),
    filterMethod: (filter, rows) =>
                  rows.filter(x=>x.nombreyape.toLowerCase().includes(filter.value.toLowerCase())),
    filterAll: true// String-based value accessors!
    }, 
    {
    Header: 'Pais',
    width: 130,
    accessor: 'nacionalidad',
    Cell: props => withCaja(props.value,dispatch,props),
    filterMethod: (filter, rows) =>
    rows.filter(x=>x.nacionalidad.toLowerCase().includes(filter.value.toLowerCase())),
    filterAll: true// String-based value accessors!
      },
    {
    Header: 'Ciudad',
    width: 180,
    accessor: 'ciudad',
    Cell: props => withCaja(props.value,dispatch,props),
    filterMethod: (filter, rows) =>
    rows.filter(x=>x.ciudad.toLowerCase().includes(filter.value.toLowerCase())),
    filterAll: true// String-based value accessors!
      },
    {
      Header: 'DNI/PAS',
      width: 150,
      accessor: 'dni',
      Cell: props => withCaja(props.value,dispatch,props),
      filterMethod: (filter, rows) =>
      rows.filter(x=>x.dni.toLowerCase().includes(filter.value.toLowerCase())),
      filterAll: true// String-based value accessors!
    },
    {
    Header: 'Vehiculos',
    width: 150,
    Cell: props => withCaja(props.value,dispatch,props),
    accessor: 'vehiculos',
    filterMethod: (filter, rows) =>
    rows.filter(x=>x.vehiculos.map(x=>(x.patente)).toString().toLowerCase().includes(filter.value.toLowerCase())),
    filterAll: true,
    Cell: props => `${props.value.length>0?props.value.map(x=>(x.patente)).toString().replace(/,/g,' | '):""}`
      },
    {
    Header: 'Parcela',
    width: 100,
    accessor: 'parcelas',
    filterMethod: (filter, rows) =>
    rows.filter(x=>x.parcelas.map(x=>x.parcela).toString().replace(/,/g,' | ').includes(filter.value.toLowerCase())),
    filterAll: true,
    Cell: props =>  withCaja(`${props.value.map(x=>x.parcela).toString().replace(/,/g,' | ')}`,dispatch,props)
      },
    {
    Header: 'Salida',
    width: 200,
    accessor: 'fechaSalida',
    Cell: props => withCaja(moment(props.value).add(3,"hours").format('dddd DD - hh:mm a'),dispatch,props),
    filterable: false
      },
      {
    Header: 'Checkout',
    width: 100,
    accessor: 'checkout',
    filterable: false,
      Cell: props => <Button style={{color:"#fff",backgroundColor:!props.row._original.deudor?"":"rgba(255, 112, 112, 0.94)"}} color={props.value=="0000-00-00 00:00:00"?props.row._original.deudor?"":"warning":"success"} onClick={()=>{
        if(window.confirm("Confirmar checkout para "+props.row.nombreyape)){
          setLoading(true);
          API.graphql(
            graphqlOperation(checkout,{id:props.row.idingresos,bool:props.value=="0000-00-00 00:00:00"})).
            then(x=>{console.log(x);actualizarTabla(setLoading,dispatch)})}
          }
        }
        >{props.value!="0000-00-00 00:00:00"?"Si":"No"}</Button>
      },
      {
    Header: 'Accion',
    width: 100,
    accessor: 'idingresos',
    filterable: false,
      Cell: props => <Button color={"info"} className={"test-editar"} onClick={()=>{navigate("/camping/nuevo");dispatch(setIngresoAction(props.row._original))}}>Editar</Button>
      }]
      console.log(listaTotal)
//Si lo ven desde un mobil
  if (mobile){
    columns=columns.filter(x=>x.accessor=="nombreyape"||
                              x.accessor=="dni"||
                              x.accessor=="vehiculos"||
                              x.accessor=="parcelas"||
                              x.accessor=="checkout"||
                              x.Header=="Accion")
    //Nombre y apellido
    columns[0].width=90;
    columns[0].Cell= (props) => (props.value.split(" ")[0]);
    columns[0].Header= "Apellido"
    
    //Nacionalidad
    columns[1].width=90;
    //Vehiculos
    columns[2].width=80;
    //Parcelas
    columns[3].width=60;
    //checkout
    columns[4].width=60;
    //Renovar
    columns[5].width=60;
    
    }
  return (
      <React.Fragment>
         <Container fluid={true}>
          <Row>
            <Col xs={12} md={{size:3}} className={"botonDivNuevoIngreso"} color={"info"} onClick={()=>{navigate("/camping/nuevo");dispatch(setIngresoAction(null))}}>Nuevo Registro </Col>
            {!deudores?
            <Col xs={12} md={{offset:5,size:2}}  onClick={()=>{setDeudores(true);setData(listaDeudores)}} className={"botonDivDeudores"}>Deudores</Col>
            :
            <Col xs={12} md={{offset:5,size:2}} style={{borderRadius:"0px 0px 0px 10px"}} onClick={()=>{setDeudores(false);setData(listaEstadia)}} className={"botonDivNuevoIngreso"}>Estancias</Col> 
            }
            {!historico?
            <Col xs={12} md={{size:2}} className={"botonDivHistorico"} onClick={()=>{setHistorico(true);setData(listaTotal)}}>Historico </Col>
            :
            <Col xs={12} md={{size:2}} style={{borderRadius:"0px 0px 0px 0px"}} className={"botonDivNuevoIngreso"}  onClick={()=>{setLoading(true);actualizarTabla(setLoading,dispatch);setHistorico(false);setData(listaEstadia)}}>Estancias </Col>
            }
          </Row>
             <br/>
         </Container>
 
         {currentIngreso&&currentIngreso.listaPagos&&<Caja setLoadingTabla={setLoading} ingreso={currentIngreso}/>}
         {/* ESTO SE QUEDA ASI HASTA QUE EXPLOTE TODO. QUIERO VER EL LIMITE
        !historico?*/
      <ReactTable   // informs React Table that you'll be handling sorting and pagination server-side                 
      noDataText={'No se encontraron registros'} className="-striped -highlight" filterable loadingText={"Cargando..."} loading={loading} columns={columns} data={data.sort(function(a, b){return moment(b.fechaIngreso).diff(moment(a.fechaIngreso))})} />
     /* <ReactTable   // informs React Table that you'll be handling sorting and pagination server-side
      pages={20}
      onFetchData={(state, instance) => {setLoading(true);
        console.log(JSON.stringify(state.filtered))
        API.graphql({
          query: listaIngresos,
          variables:{filtered:JSON.stringify(state.filtered)},
          authMode: 'AMAZON_COGNITO_USER_POOLS'
        }).then((res)=>{setLoading(false);dispatch(res.data.listaIngresos)}).catch((error)=>{alert(error.errors[0].message);})*/}

      {//DESCOMENTAR ESTO CUANDO HAGAS LA PARTE DEL CAMPING (NO COMPILA PERO ES EL IMPRIMIR)
      //currentIngreso&&modal&&<SecondPage ingreso={currentIngreso}></SecondPage>
      }
    </React.Fragment>
   
)}
const Wrapper=(props)=>(
  <Layout>
    <SEO title="Nuevo Registro" />
    <IndexPage {...props}></IndexPage>
  </Layout>
)
export default connect(state=>({modal:state.camping.modal,listaIngresos:state.camping.listaIngresos,currentIngreso:state.camping.ingreso,mobile:state.camping.mobile}),null)(Wrapper)

