import React,{useState,useEffect} from "react"
import ReactTable from 'react-table-6'
import moment from 'moment'
import LoadingOverlay from 'react-loading-overlay';
import { Button,Col,Row,Input,Modal,InputGroupText, ModalHeader, ModalBody, ModalFooter,InputGroup,InputGroupAddon,Label,FormGroup } from 'reactstrap';
import {MandarFormulario} from "../../Funciones/utils"
import {connect} from 'react-redux'
//ACTIONS
import setModalAction from '../../redux/actions/setModalCaja'

const ReiniciarModal=(setTipo,setDescripcion,setImporte,setForma,setNoches,setMediaEstadia,setNumeroHoja,setCondicion)=>{
  setTipo("Pago")
  setDescripcion("1 noche");
  setImporte(0)
  setForma("")
  setNoches(1)
  setMediaEstadia(false)
  setNumeroHoja(false)
  setCondicion("Contado")
}
export const Caja = ({modal,dispatch,ingreso,testFunction,setLoadingTabla,...props}) => {
  const [tipo,setTipo]=useState("Pago")
  const [descripcion,setDescripcion]=useState("")
  const [importe,setImporte]=useState(0)
  const [forma,setForma]=useState("")
  const [condicion,setCondicion]=useState("Contado")
  const [noches,setNoches]=useState(1)
  const [mediaEstadia, setMediaEstadia] = useState(false);
  const [loading,setLoading] =useState(false);
  const [numeroHoja,setNumeroHoja] =useState(false);
    useEffect(()=>{
      if (noches<0) {
        alert("Las noches tienen que ser positivas")
        setNoches(0)
      }
      setImporte((mediaEstadia)?ingreso.precioxnoche*noches+ingreso.precioxnoche/2:ingreso.precioxnoche*noches)
      setDescripcion(`${noches} ${(noches==1)?"noche":"noches"}`)
    },[noches,mediaEstadia,modal])
    useEffect(()=>{

      if (importe<0) {
        alert("El importe tiene que ser positivo")
        setImporte(0)
      }

    },[importe])
    const tipos=["Pago","Devolucion"]
    var formas=["","Efectivo","Tarjeta"]
    var condiciones=["Contado"]
    if (tipo !="Devolucion"){
      condiciones=[...condiciones,"Cta/cte"]
    }
     var columns = [
    {
    Header: 'Fecha',
    accessor: 'fecha',
    width: 220,
    Cell: props =>(moment(props.value).add(3,"hours").format('DD/MM/YYYY - hh:mm a')),
    filterable: false
      },
    {
    Header: 'Tipo',
    accessor: 'tipo',
    width: 120,
    filterable: false
      },
    {
    Header: 'Descripcion',
    accessor: 'descripcion',
    width: 150,
    filterable: false
      },
    {
    Header: 'Importe',
    accessor: 'importe',
    width: 80,
     Cell: props =>`$ ${props.value}`,
    filterable: false
      },
    {
    Header: 'Condicion venta',
    accessor: 'condicion',
    width: 150,
    filterable: false
      },
    {
    Header: 'Forma de pago',
    accessor: 'forma',
    width: 150,
    filterable: false
      },
    
    ]
  const hojaNumeroAux=parseInt(Math.random()*10000)*2;
  const fechaSalida=moment(ingreso.fechaSalida).add((tipo=="Devolucion")?-noches:noches,'days').set({hour:(mediaEstadia)?18:11,minute:0,second:0,millisecond:0})

  return (
  
     <Modal style={{maxWidth:"1000px"}} size={"lg"} isOpen={modal} toggle={()=>{ReiniciarModal(setTipo,setDescripcion,setImporte,setForma,setNoches,setMediaEstadia,setNumeroHoja,setCondicion);dispatch(setModalAction(false))}} >
        <LoadingOverlay
        active={loading}
        spinner
        text='Agregando'
        >
        <ModalHeader toggle={()=>{ReiniciarModal(setTipo,setDescripcion,setImporte,setForma,setNoches,setMediaEstadia,setNumeroHoja,setCondicion);dispatch(setModalAction(false))}}
        >Cuenta de {ingreso.nombreyape}
        </ModalHeader>
        
        <ModalBody>
        <Row>
        
        </Row>
        <Row>
           <Col xs={12} md={3} > 
           <h4 onClick={()=>setNumeroHoja(!numeroHoja)} style={{color:(numeroHoja)?"green":"black"}}>Tipo</h4><Input value={tipo} onChange={(e)=>{setTipo(e.target.value)}}
           type="select">{tipos.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
           </Col>
           <Col xs={12} md={2}> 
           <h4>Condicion</h4><Input value={condicion} onChange={(e)=>{setCondicion(e.target.value)}}
           type="select">{condiciones.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
           </Col>
           <Col xs={12} md={3}> 
           <h4>Forma</h4><Input id={"selectCondicion"} name="forma" value={forma} onChange={(e)=>{if(e.target.value=="Efectivo"){setNumeroHoja(true)}; setForma(e.target.value)}}
           type="select">{formas.map((x,i)=>(<option key={i}>{x}</option>))}</Input>
           </Col>
            <Col xs={12} md={4} > 
           <h4>Descripcion</h4><Input  type="textarea" value={descripcion} onChange={(e)=>{setDescripcion(e.target.value)}}
           />
           </Col>
      
        </Row>
        <br/>
        
        <Row>
         <Col xs={12} md={{size:2,offset:3}}> 
           <h4 style={{margin:0}}>Noches</h4><Input style={{fontSize:"26px",fontWeight:800,textAlign:"center"}} type="number" id="selectNoches" name="noches" value={noches} onChange={(e)=>{setNoches(e.target.value)}}/>
         </Col>
         <Col xs={12} md={4}> 
         <Row>
          <Col xs={5}> <h4>Importe</h4></Col>
          <Col xs={7}> <h5 >${ingreso.precioxnoche} x noche</h5></Col>
         </Row>
          <InputGroup>
           <InputGroupAddon addonType="prepend">
                <InputGroupText>$</InputGroupText>
            </InputGroupAddon>
              <Input type="number" style={{fontSize:"26px",fontWeight:800}} value={importe} onChange={(e)=>{setImporte(e.target.value)}}/>
               <InputGroupAddon addonType="append">
                 <Button id={"buttonTest"} style={{float:"right"}} color="info" size={"lg"} onClick={()=>{
            if (forma==""){
              alert("Falta poner la forma de pago")
            }
            else{
              setLoading(true)
              const mandar=()=>{
                console.log(ingreso.parcelas)
                MandarFormulario(
                ingreso.nombreyape,
                ingreso.edad,
                ingreso.nacionalidad,
                ingreso.ciudad,
                ingreso.provincia,
                ingreso.dni,
                ingreso.vehiculos,
                ingreso.parcelas.map(x=>x.parcela),
                ingreso.menores,
                ingreso.mayores,
                ingreso.carpas,
                ingreso.trailers,
                ingreso.fechaIngreso,
                fechaSalida.format(),
                ingreso.precio,
                [...ingreso.listaPagos,{descripcion,forma,condicion,importe,noches,tipo,hojaNumero:(numeroHoja)?hojaNumeroAux+1:hojaNumeroAux,fecha:moment().format()}],
                ingreso.checkout,
                setLoading,
                ingreso.idingresos,
                true,
                dispatch,
                testFunction
                );}
                if (ingreso.listaPagos==0){
                  //printJS({type:"html",printable:"imprimir"})
                }
                mandar();
            }
           }
            }>{tipo=="Devolucion"?"Devolver":"Pagar"}</Button>
            </InputGroupAddon>
           </InputGroup>
         </Col>
           <Col xs={3} md={3}>
           <br/>
           <br/>
          <FormGroup check >
            <Label check>
              <Input checked={mediaEstadia} onChange={(e)=>{setMediaEstadia(e.target.checked)}} type="checkbox" name="checkbox1" />{' '}
              Media Estadia
            </Label>
          </FormGroup>
        </Col>
        </Row>
        
        <br/>
        <Row>
          {/*<Col xs={12} md={5}><h5>Ingreso: {moment(ingreso.fechaIngreso).format('dddd DD - hh:mm a')}</h5></Col>*/}
        </Row>
        <Row>
          {/*<Col xs={12} md={5}><h5>Salida: {moment(ingreso.fechaSalida).add(3,'hours').format('dddd DD hh:mm a')}</h5></Col>*/}
          <Col style={{textAlign:"center"}} xs={12} md={12}><h5>Salida nueva: {fechaSalida.format('dddd DD - hh:mm a')}</h5></Col>
        
        </Row>
       
        <br/>
        <Row>
          <Col xs={12} md={{size:12}} >
           
          </Col>
        </Row>
        
        <br/>
           <ReactTable 
          defaultPageSize={5}
          noDataText={'No hay pagos'} 
          className="-striped -highlight" 
          filterable 
          loadingText={"Cargando..."} 
          loading={false} columns={columns} 
          data={ingreso.listaPagos.sort(function(a, b){return moment(b.fecha).diff(moment(a.fecha))})} />
          <br/>

          <Row>
            <Col xs={4} >
              <h4 style={{textAlign:"center"}}>Noches pagas</h4>
            </Col>
            <Col xs={8}>
              <h4>{ingreso.listaPagos.map(x=>x.noches).reduce((a,b) => a + b, 0)}</h4>
            </Col>
          </Row>
          <Row>
            <Col xs={4} >
              <h4 style={{textAlign:"center"}}>Total abonado</h4>
            </Col>
            <Col xs={8}>
              <h4>${ingreso.listaPagos.filter(x=>x.tipo=="Pago"&&x.condicion!="Cta/cte").map(x=>x.importe).reduce((a,b) => a + b, 0)}</h4>
            </Col>
          </Row>
      
          <Row>
            <Col xs={4} >
              <h4 style={{textAlign:"center"}}>Total Cta corriente</h4>
            </Col>
            <Col xs={8}>
              <h4>${ingreso.listaPagos.filter(x=>x.condicion=="Cta/cte").map(x=>x.importe).reduce((a,b) => a + b, 0)}</h4>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
        <Button color={"info"} onClick={()=>{/*printJS("imprimir","html")*/}}>Imprimir</Button>
        <Button color="secondary" className={"buttonTest"} onClick={()=>{ReiniciarModal(setTipo,setDescripcion,setImporte,setForma,setNoches,setMediaEstadia,setNumeroHoja,setCondicion);dispatch(setModalAction(false))}}>Cerrar</Button>
        </ModalFooter>
        </LoadingOverlay>
      </Modal>
   
    )
}



export default connect(state=>({modal:state.camping.modal}),null)(Caja)

